import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Enrollment } from './enrollment'
import Footer from './components/footer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import 'react-datetime/css/react-datetime.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faFilePdf,
    faTimesCircle,
    faSearch,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faSquare,
    faBars,
    faArrowDown,
    faTimes,
    faSlidersH,
    faCaretUp,
    faCaretDown,
    faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons'
import './i18n'
import Header from './components/header'
import { PageContainer, PageWrapper } from './components/styles/Header.styled'
import './styles.css';
import Success from './success'
import Failure from './failure'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DraggableModalProvider } from 'ant-design-draggable-modal';
import 'ant-design-draggable-modal/dist/index.css';
import ChatBotAgent from './components/agentChatBot/ChatBotAgent'
import isAdmin from './utils/isAdmin'
import SwitchHoldStatus from './components/enrollment/AgentWebPortal/SwitchHoldStatus'
import Tracker from '@openreplay/tracker';
import { openReplayCSEKey, openReplaySecureMode } from './config'
import ExtraPage from './extrapage'
import CookiePopup from './components/CookiePopup'
import getCookieData from './utils/getCookieData'

library.add(
    faFilePdf as any,
    faTimesCircle as any,
    faSearch as any,
    faCheckCircle as any,
    faCheckSquare as any,
    faCircle as any,
    faSquare as any,
    faBars as any,
    faArrowDown as any,
    faTimes as any,
    faSlidersH as any,
    faCaretUp as any,
    faCaretDown as any,
    faExchangeAlt as any
)

const theme = {
    colors: {
        primary: '#FFAF19',
        secondary: '#0bcebb'
    },
}

const tracker = new Tracker({
    projectKey: openReplayCSEKey,
    __DISABLE_SECURE_MODE: openReplaySecureMode
});

function App() {
    const { i18n } = useTranslation();
    const [ isTx, setIsTx ] = useState(false);
    const [ enableSwitchLang, setEnableSwitchLang ] = useState(true);
    const [isAgent,setAgent] = useState(false)
    const [ cookiesAccepted, setCookiesAccepted ] = useState(false);
    const [scriptData,setScriptData] = useState({
        scriptHead:"Screen 1",
        script:"No Script Available",
        dynamicData:{}
    })

    const [ openreplayData, setOpenreplayData] = useState({})

    const handleSetIsTx = (_isTx: boolean) => {
        setIsTx(_isTx);
    }

    const handleSetEnableSwitchLang = (val: boolean) => {
        setEnableSwitchLang(val);
        
        if (!val) i18n.changeLanguage('en');
    }

    useEffect(()=>{
        (async()=>{
            let cookieRes = getCookieData("cookiesAccepted");
            let admin = await isAdmin();
            if(cookieRes === "true" || admin){
                await tracker.start();
                setOpenreplayData({
                    sessionId: tracker.getSessionID()
                })
            }
            // let res = await getConfigById("SHOW_CHAT_BOT");
            // const flag = Boolean(Number(res?.config_details?.ConfigData))
            // SET_SHOW_CHAT_BOT(flag)
        })()
    },[cookiesAccepted])

    useEffect(()=>{
        (async()=>{
            let _isAdmin = await isAdmin();
            setAgent(_isAdmin)
        })();
    },[])
    
    return (
        <ThemeProvider theme={theme}>
            <PageWrapper>
                <PageContainer>
                    <DraggableModalProvider>
                        <Header scriptData={scriptData} enableSwitchLang={enableSwitchLang} />
                        {/* {isAgent && <ChatBotAgent scriptData={scriptData}/>} */}
                        <BrowserRouter>
                            <Routes>
                                <Route path="/:urlStep" element={<Enrollment setScriptData={setScriptData}  handleSetIsTx={handleSetIsTx} handleSetEnableSwitchLang={handleSetEnableSwitchLang} openReplayTracker={tracker} openreplayData={openreplayData} isAdmin={isAgent}/>} />
                                <Route path="/" element={<Enrollment setScriptData={setScriptData} handleSetIsTx={handleSetIsTx} handleSetEnableSwitchLang={handleSetEnableSwitchLang} openReplayTracker={tracker} openreplayData={openreplayData} isAdmin={isAgent}/>} />
                                <Route path="/success" element={<Success />} />
                                <Route path="/failure" element={<Failure />} />
                            </Routes>
                        </BrowserRouter>
                        {/* <CookiePopup setCookiesAccepted={setCookiesAccepted}/> */}
                    </DraggableModalProvider>
                </PageContainer>
                <Footer isTx={isTx} />
            </PageWrapper>
            <ToastContainer />
        </ThemeProvider>
    )
}

export default App
